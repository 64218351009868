import { defineMessages } from 'react-intl'

/* eslint-disable max-len */

export default defineMessages({
  heroTitle: 'Modernization and Individualization of a Tourism Platform',
  name: 'S-Markt und Mehrwert',
  heroDescription:
    'The partnership between the companies contributed to making the tourism platform fit for the future and better meet the needs of end customers.'
})
