import { defineMessages } from 'react-intl'

/* eslint-disable max-len */
export default defineMessages({
  heroTitle: 'Multi-brand enterprise booking platform',
  name: 'DER Touristik Suisse AG',
  heroDescription: 'With its brands Kuoni, Helvetic Tours, and lastminute.ch, DER Touristik Suisse AG is the Swiss market leader. It now operates all of its booking websites with only a single enterprise booking platform using freshcells fusion and TravelSandbox® technology.',
  fact1Text: 'The upcoming discontinuation of support for amadeus Leisure (formerly TravelTainment) TT-IBE premium presented DER Touristik Suisse AG, whose brands include Kuoni, Helvetic Tours, and lastminute.ch, with the challenge of rearranging its online business. \n\nNaturally, the brands\' existing booking platforms should be retained. Offers from Amadeus should continue to be sourced and it was the client\'s wish that the new frontends be contemporary and technologically up to date. Hosting was to be cloud-based and the application should be ready for high traffic thanks to scalable architecture. \n\nThese parameters meant that DER Touristik Suisse AG was interested in finding a reliable partner capable of safely mastering such a technical challenge in time.',
  fact2Text: 'The Quickstart complete solution for online travel platforms, which was first unveiled at the ITB 2020, delivered the ideal prerequisites for having the required booking platforms ready for DER Touristik Suisse AG in time. Building on our proprietary TravelSandbox®, which has already proven its scalable and stabile architecture in many online travel platforms, Quickstart possesses a design framework that realizes performant and user-friendly frontends within a very short time period. \n\nMoreover, Fusion facilitates the requested connection to the Amadeus Booking API. However, should DER Touristik Suisse go in a different direction at some point in the future and use an alternative offer API, Fusion will make this transition almost seamless without having to again change the complete booking platform. \n\nInstead of having to use and operate individual platforms for each brand, Quickstart Enterprise facilitates the use of white labels. The common architecture immensely reduces maintenance work without having to sacrifice the unique identities of the individual brands. Fusion even makes it possible to connect to a variety of offer sources for the individual white labels. \n',
  fact3Text: 'The Quickstart complete solution for online travel platforms, which was first unveiled at the ITB 2020, delivered the ideal prerequisites for having the required booking platforms ready for DER Touristik Suisse AG in time. Building on our proprietary TravelSandbox®, which has already proven its scalable and stabile architecture in many online travel platforms, Quickstart possesses a design framework that realizes performant and user-friendly frontends within a very short time period. \n\nMoreover, Fusion facilitates the requested connection to the Amadeus Booking API. However, should DER Touristik Suisse go in a different direction at some point in the future and use an alternative offer API, Fusion will make this transition almost seamless without having to again change the complete booking platform. \n\nInstead of having to use and operate individual platforms for each brand, Quickstart Enterprise facilitates the use of white labels. The common architecture immensely reduces maintenance work without having to sacrifice the unique identities of the individual brands. Fusion even makes it possible to connect to a variety of offer sources for the individual white labels.',
  welcomeText: 'DER Touristik Suisse AG is a Swiss market-leading tourism company whose travel operators Kuoni, lastminute.ch, Helvetic Tours, and ten specialized travel operators cover the entire spectrum of travel. \n\nIts service network includes over 80 travel agencies from Kuoni, Helvetic Tours, and rewi throughout Switzerland. More than a thousand motivated staff work in these offices and at the headquarters in Altstetten, Zurich, where they serve customers who love to travel.',
  welcomeTitle: 'DER Touristik Suisse AG',
  logosSliderTitle: 'One platform – several brands',
  infoTitle: 'Operating multiple brands with a single code base',
  infoItem1: 'Created 4 white labels within a few days',
  infoItem2: 'Common code base, configurations, and APIs',
  infoItem3: 'Individual configurations, features, and design for each white label',
  stat1Value: 'on the market',
  stat2Value: 'destinations',
  stat3Value: 'in annual sales',
  stat4Value: 'emergency service',
  featuresTitle: 'Conversion-enhancing features',
  functionality1Title: 'Innovative offer configuration',
  functionality1Item1: 'Powerful and complex search queries',
  functionality1Item2: 'Intuitive product configuration',
  functionality1Item3: 'Modular booking integration with out-of-the-box solutions',
  functionality2Title: 'Innovative travel search',
  functionality2Item1: 'Multiple languages, markets & currencies ready',
  functionality2Item2: 'Utilization of several product sources over Fusion',
  functionality2Item3: 'Addition of white labels within a few days',
  functionality2Item4: 'Intelligent product management',
  functionality3Title: 'From beach holidays to city trips and cruises',
  functionality3Item1: 'Premium package trips for every vacation budget since 1906',
  functionality3Item2: 'Exclusive partners and brands, such as Kuoni, Manta reisen, helvetic tours',
  functionality3Item3: 'Relaxed arrivals and departures with transfers',
  advantagesTitle: 'New sales channels',
  advantage1: 'Unlimited white labels on a single code base',
  advantage2: 'Individual design and features for each white label',
  advantage3: 'Simpler launch for new brands and partnerships',
  feedbackText: 'Working together with the freshcells UI/UX team was a pleasure and exceeded all of our expectations.',
  feedbackFrom: '<b>Simon Zürcher</b> – Lead Webdesigner, DER Touristik Suisse AG',
  feedbackText2: 'I had already worked with freshcells as the CEO of holidays AG, and I now had the opportunity to implement another incredible project with them for DER Touristik Suisse AG.',
  feedbackFrom2: '<b>Manuel Posh</b> – Leiter-Commerce, DER Touristik Suisse AG',
  feedbackText3: 'We were able to develop the new platform together with freshcells on time and well equipped for the future.',
  feedbackFrom3: '<b>Michael Hofer</b> – Projektleiter, DER Touristik Suisse AG',
  reviewTitle1: 'Kuoni - Gladly again',
  reviewText1: 'Good value hotel booked. Gladly again with Kuoni.',
  reviewTitle2: 'Cheap deals with useful reviews',
  reviewText2: 'I have booked my package tours several times with Kuoni, always satisfied.',
  reviewTitle3: 'Perfect even in times of crisis',
  reviewText3: 'Various restrictions to different countries are pointed out.The booking was made online and uncomplicated',
  featuresDoneTitle: 'A head start through technology',
  techTitle: 'Utilized technologies',
  tech1: 'freshcells Quickstart',
  tech2: 'freshcells TravelSandbox® framework with scalable, managed Kubernetes-cloud architecture',
  tech3: 'freshcells Fusion offer-source management for connecting Amadeus Travel APIs as a product source via Fusion',
  tech4: 'ReactJS, GraphQL, Strapi Headless CMS, and much more',
  designText: 'All our products utilize a standardized responsive design to look incredible on any device. We use innovative UI elements to create unique mobile experiences.',
  design1: 'Fully fluid responsive design for all devices',
  design2: 'Retina-ready and optimized for speed even over low bandwidths'
})
