import { defineMessages } from 'react-intl'

/* eslint-disable max-len */
export default defineMessages({
  heroTitle: 'Exclusive clubs in Europe and Africa',
  name: 'Aldiana',
  heroDescription:
    'Aldiana offers high quality holidays under the motto "Holidays with friends", because Aldiana is not an institution, not a formula and not a place, but an attitude towards life.',
  fact1Text:
    'Club holidays, which like Aldiana include sports activities, childcare, wellness and all-inclusive offers, are currently very popular. According to surveys by the German Travel Association (DRV), the willingness of tourists to pay a luxury surcharge for travel has increased significantly in recent years.',
  fact2Text:
    'With freshMS® as a powerful, user-friendly and agile tool Aldiana is enabled to create, manage and adjust the content on all parts of their page, create SEO and landing pages on their demand and set up special offers live within seconds.',
  fact3Text:
    'A modern, slick and stylish designed website combined with beautiful images to transport emotion reflects Aldiana CI as a young, fresh and one of a kind company for younger travelers, couples and families, that are looking for more than just a "vacation".',
  welcomeText:
    'Aldiana Clubs & Resorts offers unique Holiday experience with a familial feeling in extraordinary facilities.\n\nHigh Quality content combined with our flexible freshMS page editing and dynamic marketing offer possibilities make this website stand out. Videos, interactive Maps and a highly flexible offer configuration creating a user experience which highlight every aspect of these Clubs & Resorts.',
  infoTitle: 'What is the target group',
  infoItem1: 'New and young target group',
  infoItem2: 'Families, with a fresh ‘lifestyle’ ',
  infoItem3: 'German-speaking customers',
  stat1Value: 'Clubs & Resorts',
  stat1Message: 'Award winning',
  stat2Value: 'Unique Club Resorts',
  stat3Value: 'In the market',
  stat4Value: 'Turnover per year',
  functionality1Title: 'Our features',
  functionality1Item1: 'Whitelabel architecture',
  functionality1Item2: 'SinglePage application',
  functionality1Item3: 'Content focused',
  functionality1Item4: 'Innovative user experience',
  functionality2Title: 'High performance',
  functionality2Item1: 'Package-upsell features',
  functionality2Item2: 'Highly customizable',
  functionality2Item3: 'Dynamic teaser control',
  solutionTitle: 'Bewotec solutions',
  solutionTextTitle: 'In under one month',
  solutionText:
    "freshcells completely replaced the existing packaging system and Thomas Cook's Nurvis booking processing software with Bewotec solutions.{br}{br}Preconfigured components for connecting the necessary settlement systems allow (as in the case of Aldiana) the packaging system, the payment processor and other third-party systems to communicate without the end customer noticing anything. Preconfigured components for connecting the necessary settlement systems allow a fast and seamless change of the packaging system, the payment processor and other third-party systems. Like proven with the Aldiana platform, without the end customer noticing anything.",
  brandLogoItem1: 'Redesign “old” Aldiana Logo',
  brandLogoItem2: 'Easy to recognize',
  brandLogoItem3: 'Brand history reproduced in a modern manner ',
  brandColorsItem1: 'Fresh and new colors',
  brandColorsItem2: 'Adult and high quality',
  brandColorsItem3: 'Travel inspired',
  brandRecognitionText:
    "We build brands with recognition value for our clients. They're state of the art, unique, and feature outstanding visuals.",
  feedbackText:
    '"Ein herzliches Dankeschön an freshcells und mein E-Commerce-Team. Sie haben gemeinsam eine hervorragende Projektarbeit geleistet und konnten eine Punktlandung zur Systemumstellung hinlegen."',
  feedbackFrom: '<b>Stefanie Brandes</b> – Geschäftsführerin Aldiana GmbH',
  videoTitle: 'All Aldiana Clubs in 60 seconds'
})
