import { defineMessages } from 'react-intl'

/* eslint-disable max-len */

export default defineMessages({
  heroTitle: 'Integration of Content Management, CRM, and Web Services',
  name: 'Mitsubishi Factory Automation',
  heroDescription:
    'As an internationally operating company with a complex product range, the Mitsubishi Electric Factory Automation web platform serves as a crucial point of contact for visitors from various markets and industries.'
})
