import { defineMessages } from 'react-intl'

/* eslint-disable max-len */
export default defineMessages({
  heroTitle: 'Innovative solution for Czech market needs',
  name: 'Etravel',
  heroDescription: 'We are true experts on last minute trips with the most flexible options. You can assemble your holiday of thousands of different options. With us, you simply have vacation in your hands.',
  fact1Text: 'The travel agency eTravel.cz has been present on the Czech market since 2003 and during this time it has arranged holidays for over tens of thousands of satisfied customers and received numerous awards from partner travel agencies. eTravel is one of the strongest tour operators in the Czech Republic.',
  fact2Text: 'To maintain this status eTravel had to keep up with the industry trend. And accordingly needed a modern, user-friendly website with state-of-the-art technology that could fulfill the requirements.',
  fact3Text: 'In order to implement such an ambitious project, a solid relationship of trust and a lot of know-how is necessary. This is how eTravel, technically up- to - date, was able to further expand its opportunities in the Czech market.And can satisfy its customers as much as possible.Because a satisfied customer is the best reference.',
  welcomeText: 'One of the most trusted tour operator and stable application in Czech Republic. Based on the UI / UX experience from international markets we have achieved a high conversion rate and got a lot of positive feedback from our partners and their customers.\n\nFirst additional whitelables are already launched.',
  infoTitle: 'About etravel',
  infoItem1: 'On the Czech market since 2003',
  infoItem2: 'Received awards from partner travel agencies',
  infoItem3: 'Daily updated offer of high-quality tours',
  stat1Value: 'Travel agencies in the portfolio',
  stat2Value: 'Destinations',
  stat3Value: 'Largest online retailers in Czech',
  stat4Value: 'Clients per year',
  functionality1Title: 'Awesome features',
  functionality1Item1: 'Multi-sources integrations',
  functionality1Item2: 'Advanced filtering',
  functionality1Item3: 'SinglePage application',
  functionality2Title: 'Technical facts',
  functionality2Item1: 'Easy content management with latest freshMS',
  functionality2Item2: 'High performance with ReactJS, Redis and Elasticsearch',
  functionality2Item3: 'Whitelable architecture',
  brandLogoItem1: 'Travel inspired',
  brandLogoItem2: 'Easy to read',
  brandLogoItem3: 'Good to remember remains in the memory ',
  brandColorsItem1: 'Young and modern color',
  brandColorsItem2: 'Travel inspired color combined',
  brandColorsItem3: 'Serious and memorable',
  brandRecognitionText: 'We build brands with recognition value for our clients. They\'re state of the art, unique, and feature outstanding visuals.'
})
