import { defineMessages } from 'react-intl'

/* eslint-disable max-len */
export default defineMessages({
  heroTitle: 'Your weekend, better than ever',
  name: 'weekend.com',
  heroDescription: 'More travel, less searching: This is the goal of weekend.com.',
  fact1Text: 'Book short weekend trips quickly and easily. Compare offers as usual and select the best offer. Very simple on the web or mobile in the app for iOS or Android.',
  fact2Text: 'The goal is to create a platform where this is possible, one which meets the needs of our target audience. And the best part: weekend.com now offers its own trips.',
  fact3Text: 'We created a website as well as an app for iOS and Android where it\'s possible to do just that. It is possible to promote your own offer as well offers from third-party providers.',
  welcomeText: 'weekend.com is a platform designed for everyone who wants to make the most of their weekends. Available online and as an iOS and Android app, the service gives customers the power to browse and book weekend getaways from their local airport(s). It functions as a meta search engine – directing customers to partners’ websites to book as well as offering its own dynamically produced, ATOL protected packages which can be booked directly.\n\nBy working with expert partners and implementing the latest technology, the platform gives customers the chance to handcraft their perfect weekend holiday, on their own terms.',
  infoTitle: 'Award winning performance',
  infoItem1: 'Awarded “The German Travel Award 2018” by Gruner & Jahr',
  infoItem2: 'Awarded Apple’s “App of the day”',
  stat1Value: 'Downloads in 1 year',
  stat2Value: 'Available (App Store & Google Play)',
  stat3Value: 'Destinations',
  stat4Value: 'Hotels',
  functionality1Title: 'Great features',
  functionality1Item1: 'High performance',
  functionality1Item2: 'Dynamic teaser offers',
  functionality1Item3: 'Rich, high-quality content throughout the platform',
  functionality2Title: 'Our distinctive details',
  functionality2Item1: 'Highly customizable build-your-own and traditional travel packages',
  functionality2Item2: 'Checkout and payment functionalities',
  brandLogoItem1: 'Contemporary',
  brandLogoItem2: 'Easy to read',
  brandLogoItem3: 'Memorable',
  brandColorsItem1: 'Relevant and future-proofed color',
  brandColorsItem2: 'Genderless color',
  brandColorsItem3: 'State of the art gradient',
  brandRecognitionText: 'We build brands with recognition value for our clients. They\'re state of the art, unique, and feature outstanding visuals.',
  feedbackText: '“With their deep knowledge of the travel industry and their skilled team, freshcells proved to be the perfect fit for the endeavor that became the successful weekend.com brand. freshcells remains a strong and reliable partner and contributor to what weekend.com is today.”',
  feedbackFrom: '<b>Tobias Boese</b> – CEO weekend.com'
})
