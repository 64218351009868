import { defineMessages } from 'react-intl'

/* eslint-disable max-len */
export default defineMessages({
  heroTitle: 'Your way to individual real time product configuration and ordering',
  name: 'Plattenplaner',
  heroDescription: 'Configure and calculate quickly and easily without registration from your kitchen top to the living room table, to the shower back panel.',
  fact1Text: 'The networking of small craft enterprises with large craft enterprises as well as the connection to trade and digital distribution is absolutely necessary. The basis for value creation in the digital world is the transfer of data into a common network (data standardization, master data creation and internationalization).',
  fact2Text: 'Transfer into the digital age. The manufacturing and assembly craft enterprises are led to economic utilization and specialization. By connecting via common networks and digital tools, distributed commissioning and joint work are made possible in a targeted manner. Cost planning, purchasing, production, logistics and assembly are brought together as an integrated, digital process.',
  fact3Text: '"My craft business" digital in your pocket & centralization in the cloud. Prices and calculations by the customer and the employees. The possibility to control inquiries, offers and orders with individual machine connection. Communication tools from inquiry to order to delivery and documentation.',
  welcomeText: 'Plattenplaner.de offers a large selection. Inexpensive, individual and secure. How does that work?\n\nOur configurator offers you countless combination possibilities. You can easily configure your desired worktop. Behind Plattenplaner.de stand experienced family businesses with tradition. Quality and craftsmanship are just as important to us as your satisfaction. With advice and action we support you through the entire decision-making process up to the finished worktop. If you wish, our local partner companies can take over the installation at your home. In addition, our certification as a trusted shops certified online store offers maximum buyer security. Convince yourself and configure your desired work surface on Plattenplaner.de now',
  infoTitle: 'What is the target group',
  infoItem1: 'Level 1: Employee-Login / Purchasing',
  infoItem2: 'Level 2: Craftsmen-Login / Purchasing',
  infoItem3: 'Level 3: Whitelabel in Website (B2B/B2C) / Online Sale',
  stat1Value: 'Launch online shop',
  stat1Message: '2018.10.12',
  stat2Value: 'Unique User',
  stat3Value: 'Created Links',
  stat4Value: 'Total Bookings',
  functionality1Title: 'The individual product & its enrichment',
  functionality1Item1: 'Fully configurable products',
  functionality1Item2: 'Digital product packaging (e.g. an entire bathroom)',
  functionality1Item3: 'Static additional products from merchandise management',
  functionality1Item4: 'Real-time calculation based on individual product parameters and daily updated prices',
  functionality2Title: 'Explicit customer requirements',
  functionality2Item1: 'Supplements by pre-assembled standard products',
  functionality2Item2: 'Addition of complementary standard products with integrated consumption calculator',
  brandLogoItem1: 'Modern and easy to recognise ',
  brandLogoItem2: 'Represents the product',
  brandLogoItem3: 'Good to adapt to other products',
  brandColorsItem1: 'Striking and modern color',
  brandColorsItem2: 'Female and Male color combined',
  brandColorsItem3: 'Recognition value',
  brandRecognitionText: 'For our clients we create brands with recognition value. State of the art, unique and with outstanding visuals.'
})
