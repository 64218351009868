import { defineMessages } from 'react-intl'

/* eslint-disable max-len */
export default defineMessages({
  heroTitle: 'Conversion increasement with new sales channels',
  name: 'Lufthansa Holidays',
  heroDescription: 'HLX Touristik GmbH powers holiday brands for Lufthansa, Swiss, Radio Energy, Condor and platforms for travelzoo, secret escapes, Urlaubs Guru and many more.',
  fact1Text: 'Many international airlines are seeing holidays as a way to promote their brand and boost customer loyalty. Which is why more and more airlines are developing their own holiday brands - as it also represents another revenue stream',
  fact2Text: 'Ancillary services generate revenues due to volumes, but holidays can deliver significantly greater revenues at much greater margins as empty seats can be sold with lower rates hidden within the package price. Typically, the airline will outsource its holiday brand to specialist partners, and one of the busiest in the industry today is HLX Touristik GmbH is a medium-sized tour operator based in Baden-Baden, Germany.',
  fact3Text: 'Specialized in combining flights and hotels in real-time to offer dynamically packaged holidays, HLX Touristik GmbH as the tour operator powers holiday brands for Lufthansa, Swiss and Condor. Ryanair Holidays has been launched in cooperation with W2M and Iberostar. HLX also supports Sixt car rental dunamically package various airlines with its car hire offers',
  welcomeText: 'Lufthansa Holidays equipped with the whitelabel technology of our TravelSandbox® is the answer to the increasing demands for online travel portals with the perfect balance between performance, UX and flexibility.\n\nLufthansa Holidays and its whitelabels offer a stable solution to target a large amount of diverse user groups.',
  logosSliderTitle: 'Whitelabels',
  infoTitle: 'Whitelabels',
  infoItem1: '5 whitelabels created in 3 months',
  infoItem2: 'Shared configurations and APIs',
  infoItem3: 'Build pages in minutes via freshMS® on-page content management',
  stat1Value: 'travellers per year',
  stat2Value: 'destinations',
  stat3Value: 'turnover per year',
  stat4Value: 'bookings per day',
  functionality1Title: 'Innovative offer configuration',
  functionality1Item1: 'High performing complex search queries (Cascade)',
  functionality1Item2: 'Intuitive product configuration features',
  functionality1Item3: 'Modular booking integration with out of the box solutions',
  functionality2Title: 'Innovative travel search',
  functionality2Item1: 'Multi-language, -market & -currency ready',
  functionality2Item2: 'Connect multiple NBC sources via mightyC',
  functionality2Item3: 'Add whitelabels within weeks',
  functionality2Item4: 'Intelligent product control',
  functionality3Title: 'From beach holidays to city trips or cruises',
  functionality3Item1: 'High-quality package tours for every holiday budget since 2010',
  functionality3Item2: 'Exclusive partners e.g. Lufthansa, Swiss, Travelzoo, Condor',
  functionality3Item3: 'Relaxed arrival and departure with transfer',
  advantagesTitle: 'New distribution channels',
  advantagesOffers: 'Linked offers on different whitelabels',
  freshMsTitle: 'Marketing powered by freshMS',
  freshMsText: 'Create landingpages with marketing teasers in minutes with freshMS® On-Page Content Management. Realtime prices, sales promotions, dynamic offer management and many more...',
  partnerPage: 'Partner pages',
  sharedConfigs: 'Shared configurations and APIs',
  feedbackText: 'Das zeitgemäße und ansprechend moderne Design ist nun auf dem neuesten Stand. «Und nicht zuletzt deutlich klarer, kompakter und frischer.»',
  feedbackFrom: '<b>Natalie Schlüter</b> – CEO, Holidays.ch AG',
  feedbackText2: '"Der Start klappte reibungslos" - Fluggesellschaften finden "ideale neue Expansionsfelder"',
  feedbackFrom2: '<b>Karlheinz Kögel</b> – Founder',
  feedbackText3: '«Wir haben damit die Tür zu einer neuen Welt aufgestossen, weil Radio Energy als populärstes Medium  für junge Menschen uns völlig neue Zielgruppen erschliesst»',
  feedbackFrom3: '<b>Sven Ickstadt</b> – Member of the Board of Directors, Holidays AG'
})
