import { defineMessages } from 'react-intl'

/* eslint-disable max-len */
export default defineMessages({
  heroTitle: 'Modern, fast & innovative',
  name: 'Bedfinder',
  heroDescription: 'Newcomer Bedfinder breaks US market in a matter of months',
  fact1Text: 'Launching a new, online brand with new product was a major growth opportunity for Hotelplan - the largest tour operator in Switzerland – as the Internet is a global platform that breaks down international boundaries - but also a major challenge. To differentiate from the competition and increase margins, Bedfinder had to offer multi supplier hotel accommodation covering both core holiday destinations as well as long tail, niche products. To increase sales and ultimately grow the business, Bedfinder also needed to reach new customer groups, in new markets and expand into new sales channels, such as traffic giant Google. The existing IT infrastructure had to be lean and based on a platform able to serve all target countries. It also needed to be scalable and most importantly, be capable of handling the mass traffic that would be generated from Google, with speed and accuracy, in order to offer an outstanding customer experience and build a loyal customer base.',
  fact2Text: 'Bedfinder could as part of the Hotelplan Holding AG quickly built its own hotel portfolio to package hotel products from external sources worldwide. Using the Player Hub technology via a high performance cache, Bedfinder instantly accesses rates, currencies, availability, business rules and languages, which are handled centrally from the numerous suppliers feeding their offers into the solution. We designed and developed the online-platform which offers the full range of search options with a innovative map search. The Google partnership enabled Bedfinder to benefit from a fully integrated, secure and compliant solution for hotel search, booking and payment, whilst capable of handling the mass traffic generated by Google.',
  fact3Text: 'In order to implement such an ambitious project, a solid relationship of trust is necessary. freshcells and Hotelplan have been working closely together for many years, starting with the relaunch of the hotelplan.ch platform. After the implementation of further projects for the Holding AG, such as the relaunch of the brands Migros Ferien, Globus Reisen and many more, the quality and good cooperation was further confirmed. On this basis it was possible to develop a corporate design for Bedfinder within a very short time and to make decisions quickly. Through years of experience in the travel industry, we were able to quickly develop a coherent UI / UX concept. The rapid implementation and high technical quality of the platform was due to the use of our TravelSandbox® technology. Technically up to date and with an innovative map search, Bedfinder had the best chances on the market right from the start. And the results prove this: In just 5 months of operation, the business has already achieved a significant turnover and a huge number of new customers. And in January 2017 alone, the site has taken an outstanding number of bookings per week.',
  welcomeText: 'Bedfinder was the proof of concept for the TravelSandbox® 3.0. With an innovative design and the single-page application approach bedfinder amazed customers all over the US.\n\nAs the first live website with freshcells’ new framework, bedfinder was the benchmark for upcoming projects.',
  stat1Value: 'bookings per week',
  stat2Value: 'customers after launch',
  stat3Value: 'accomodations',
  stat4Value: 'till launch',
  functionality1Title: 'Unique features',
  functionality1Item1: 'Points of Interest integration via socialmedia API’s',
  functionality1Item2: 'Advanced map-search',
  functionality1Item3: 'Innovative user experience',
  functionality2Title: 'The package-upsell',
  functionality2Item1: 'Package-upsell features',
  functionality2Item2: 'Easy and individual booking',
  functionality2Item3: 'Very personal and unique',
  brandLogoItem1: 'A clear visual message',
  brandLogoItem2: 'Proxima Nova as a timeless modern font',
  brandLogoItem3: 'Map marker and house',
  brandColorsItem1: 'Fresh and friendly',
  brandColorsItem2: 'Serenity and statics',
  brandColorsItem3: 'Recognition value',
  brandRecognitionText: 'The Bedfinder Brand was not used for ages and almost forgotten till it should be revived for this ambious project. A friendly brand with a clear message "find accomodations" was created in a matter of hours.',
  feedbackText: '"After a short project phase, we succeeded in bringing Bedfinder to the start - as a first step in the USA. The start was successful and opens up a promising vision for the future".',
  feedbackFrom: '<b>Thomas Stirnimann</b> – CEO Hotelplan Group',
  feedbackText2: '"freshcells did a great job in creating the Bedfinder website in many ways! I was especially impressed by the time schedule in which the front-end of the booking engine was developed! The promised features were completed "On Time"."',
  feedbackFrom2: '<b>Raffael Grütter</b> – CEO Hotelplan Group'
})
