import { defineMessages } from 'react-intl'

/* eslint-disable max-len */

export default defineMessages({
  backlinkLabel: 'Case study - Santa Claus',
  name: 'Santa Claus',
  title: 'freshcells develops <b>digitalization platform</b> for Santa Claus',
  description: '<p>We are happy to help Santa and you finally make all data usable and optimize processes through modern, easy-to-use interfaces.</p>',
  titleLink: 'Read case study',
  teamGalleryTitle: 'freshcells wishes <b>happy holidays</b>',
  overviewText: 'Also Santa Claus had a high demand to take digitalization to the next level. Learn how we help Santa and you finally make all data usable and optimize processes through modern, easy-to-use interfaces.'
})
