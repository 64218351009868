import { defineMessages } from 'react-intl'

/* eslint-disable max-len */

export default defineMessages({
  heroTitle: 'The <b>unique</b> way to share <b>travel offers</b> with your followers',
  name: 'Ytravel',
  heroDescription:
    'Ytravel is the new way to share travel offers with your followers. This platform is a unique and innovative way to create ready to share travel packages.',
  fact1Text:
    'If you see a nice spot in your e.g. instagram feed and you want to travel there its really hard to do. You have to separately search for the location, flight, accommodation and transport. Also its currently not possible to create a ready to book offer link and get commission for each booking.',
  fact2Text:
    'The solution is to have a platform which you can use to create travel offer links and share these links on your social media under you travel pics. And the best is: for every booking you got paid.',
  fact3Text:
    'We create a web app where its possible to do exactly this. You are able to use our partner websites to create a link to your offer. Also you are able to maintain your links and have a bunch of analysis tools to track how your links perform.',
  welcomeText:
    'Ytravel is the new way to share travel offers with your followers. This platform is a unique and innovative way to create ready to share travel packages. Share them and collect commission if someone is booking this trip. To get the most of this experience you could use the generated links via Ytravel to give your content a huge value. Why? Because now everyone could travel, with one tab of this link, to this awesome place you post on your social media. And the best is, You will get paid for every booking which is generated with your link.',
  infoTitle: 'What is the target group',
  infoItem1: 'People between 18 - 39 Years',
  infoItem2: 'Blogger which have a lot of expertize in travel',
  infoItem3: 'Influencer with many followers',
  stat1Value: 'Downloads',
  stat2Value: 'Unique User',
  stat3Value: 'Created Links',
  stat4Value: 'Total Bookings',
  functionality1Title: 'Create offer link',
  functionality1Item1: 'Choose a Partner you want to coorporate with',
  functionality1Item2: 'Choose a offer via iFrame',
  functionality1Item3: 'Check selected offer and add a unique linkname',
  functionality1Item4: 'Create the share link',
  functionality2Title: 'Ready to share link',
  functionality2Item1: 'Easy to copy created links',
  functionality2Item2: 'Switch directly to your social media',
  functionality2Item3: 'Create awesome content and add the shared link into bio',
  functionality3Title: 'Request for payout',
  functionality3Item1: 'Wait for booking via your shared link',
  functionality3Item2: 'See your wallet grow',
  functionality3Item3: 'Request payout easily via one click',
  brandLogoItem1: 'Generation Y inspired',
  brandLogoItem2: 'Easy to read',
  brandLogoItem3: 'Good to adapt other products with same brand',
  brandColorsItem1: 'Young and modern color',
  brandColorsItem2: 'Female and Male color combined',
  brandColorsItem3: 'State of the art gradient',
  brandRecognitionText:
    'For our clients we create brands with recognition value. State of the art, unique and with outstanding visuals.',
  feedbackText:
    'freshcells is the best partner to work with. Highly competent and technically on a really high level.{br}Good to see that Ytravel is in good hands',
  feedbackFrom: '<b>Julian Kögel</b> – CEO Y-Travel'
})
